define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common/visual/visual',
  'navigation',
  'common',
  'radio',

  'text-loader!worker/tag-edit/layout.tpl'
], function($, _, Backbone, darsan, visual, navigation, common, radio,
            titleEditTemplate){
  'use strict';
  return Object.create(visual).extend({

    parent: null,

    create: function(el, opt)
    {
      var me = this;

      visual.create.apply(me,arguments);

      var WorkerView = Backbone.TemplateCooperView.extend({
        events: {
          'change select[multiple="multiple"]': 'test',

          'click #testEv1': 'test'
        },

        template: titleEditTemplate,
        common: common,
        navigation: navigation,

        test: function(ev)
        {
          if (!ev.added && !ev.removed) return;
          if (ev.added && ev.removed) return;

          var _this = this;

          var el = $(ev.target);
          var name = $(ev.target).attr("name");
          var val = ev.val;
          var toOld;

          el.closest('.form-group').removeClass('has-error');
          _this.$el.find("#help-value").addClass("hidden");

          if(ev.added) {
            toOld = _.without(el.val(), ev.added.id);
          } else {
            if (!val.length) {
              el.val(ev.removed.id);
              el.select2('val', ev.removed.id);

              el.closest('.form-group').addClass('has-error');
              _this.$el.find("#help-value").removeClass("hidden").html("Поле не должно быть пустым");

              el.removeClass("animate_fail").addClass("animate_fail").delay(1000).queue(function(){
                $(this).removeClass("animate_fail").dequeue();
              });

              return;
            }

            toOld = _.union(el.val(), [ev.removed.id]);
          }


          var el_animate = el.prev().children();

          var hash = { value: val };

          var ownersChannel = radio.channel('owners');

          darsan.patch("", "darsan", "/worker" + me.state._rest, hash, {mute: true})
            .done(function(){
              el_animate.removeClass("animate_success").addClass("animate_success").delay(1000).queue(function(){
                $(this).removeClass("animate_success").dequeue();
              });

              el.attr('data-old-val', toOld.join(",") );
              $("[data-undo='" + name + "']").removeClass('hidden');

              _this.model.set('value', val);

              ownersChannel.trigger('module:worker-tag:change');
            })
            .fail(function(xhr){
              el.closest('.form-group').addClass('has-error');
              $("#help-"+name).removeClass("hidden").html(darsan.errorText(xhr));

              el.removeClass("animate_fail").addClass("animate_fail").delay(1000).queue(function(){
                $(this).removeClass("animate_fail").dequeue();
              });
            });
        },

        render: function()
        {
          var _this = this;
          var markup = this.compiled( {model: this.model.toJSON(), permTag: _.indexBy(me.options.permissionsTag, 'name') } );
          this.$el.html(markup);

          var typeOfAddTag = function(tag) {
            var permTag =  _.indexBy(me.options.permissionsTag, 'name');

            var inputTags = {
              'string': $("<input />", {type: 'text', class: 'text-cuper'}),
              'number': $("<input />", {type: 'number', step: 'any', class: 'text-cuper'}) ,
              'boolean': $('<select />', {class: 'select-cuper'}).append($("<option />", {value: 1}).html("да")).append($("<option />", {value: 0}).html("нет")),
              'oneof': $("<select />", {class: 'select-cuper'}),
              'darsan-oneof': $("<select />", {class: 'select-cuper'}),
              'darsan-array': $("<select />", {class: 'select2-multiple', multiple: 'multiple'})
            };

            var valSelect = tag;
            var typeTag = permTag[tag].type;
            var inputTag = inputTags[typeTag];

            inputTag.attr('id', 'value');
            inputTag.attr('name', 'value');
            inputTag.addClass('form-control');

            if (typeTag === 'darsan-oneof' || typeTag === 'darsan-array') {
              darsan.get(permTag[valSelect].prefix, permTag[valSelect].topic, permTag[valSelect].path, "")
                .done(function(data){
                  var option = $("<option />", {value: '*'}).html("Все");
                  if (typeTag === 'darsan-array') {
                    inputTag.append( option );
                  }
                  if(_.indexOf(_this.model.toJSON().value, "*") !== -1) {
                    option.attr('selected', 'selected');
                  }

                  _.each( data, function(val){
                    var option = $("<option />", {value: val.entity}).html(val.display_name);

                    if ( _.indexOf(_this.model.toJSON().value, Number(val.entity)) !== -1 || _.indexOf(_this.model.toJSON().value, String(val.entity)) !== -1 )
                    {
                      option.attr('selected', 'selected');
                    }
                    inputTag.append( option);

                  });
                  _this.$el.find("select#value").select2();
                });
            }

            if (typeTag === 'oneof') {
              _.each( permTag[tag].values, function(val){
                inputTag.append( $("<option />", {value: val}).html(val));
              });
              _this.$el.find("select#value").select2();
            }

            if (_.lastIndexOf(['darsan-oneof', 'darsan-array', 'oneof', 'boolean' ], typeTag) !== -1) {
              _this.$el.find('[data-undo="value"]').addClass('select-cuper-undo');
            } else {
              _this.$el.find('[data-undo="value"]').removeClass('select-cuper-undo');
            }
            return(inputTag);
          };

          var inputTag = typeOfAddTag(this.model.toJSON().tag);
          inputTag.val(this.model.toJSON().value);
          var oldInput = this.$el.find("#value");
          this.$el.find("#value").parent().prepend(inputTag);

          oldInput.select2('destroy');
          oldInput.remove();

          return this;
        },

        select2Change: function(ev){
          var _this = this;

          var el = $(ev.target);
          var name = $(ev.target).attr("name");
          var val = $(ev.target).val();

          var toOld = el.val();

          var el_animate = el.prev().children();
          if (ev.type === 'select2-removing') {
            val = _.without(val, ev.val);
          } else {
            val = _.union(val, [ev.val]);
          }

          var hash = { value: val };

          var ownersChannel = radio.channel('owners');

          darsan.patch("", "darsan", "/worker" + me.state._rest, hash, {mute: true})
            .done(function(){
              el_animate.removeClass("animate_success").addClass("animate_success").delay(1000).queue(function(){
                $(this).removeClass("animate_success").dequeue();
              });

              el.attr('data-old-val', toOld.join(",") );
              $("[data-undo='"+name+"']").removeClass('hidden');

              _this.model.set('value', val);

              ownersChannel.trigger('module:worker-tag:change');
            })
            .fail(function( xhr){
              el.closest('.form-group').addClass('has-error');
              $("#help-"+name).removeClass("hidden").html(darsan.errorText(xhr));

              el.removeClass("animate_fail").addClass("animate_fail").delay(1000).queue(function(){
                $(this).removeClass("animate_fail").dequeue();
              });
              event.preventDefault();
            });

        },

        undoSelect2Multi: function(el_undo){
          var _this = this;
          el_undo = $(el_undo);

          var el = _this.$el.find("#"+(el_undo).attr('data-undo') );

          var newTest = el.attr('data-old-val').split(',');
          var old = el.val();

          var el_animate = el.prev().children();
          var ownersChannel = radio.channel('owners');

          darsan.patch("", "darsan", "/worker" + me.state._rest, toPut).done(function() {
            el_animate.removeClass("animate_success").addClass("animate_success").delay(1000).queue(function () {
              $(this).removeClass("animate_success").dequeue();
            });
            el.attr('data-old-val', old.join(","));
            el_undo.addClass('hidden');

            el.select2('val', newTest);
            _this.model.set('value', newTest);

            ownersChannel.trigger('module:worker-tag:change');
          });
        }
      }); // WorkerView

      me.view = new WorkerView({
        el: me.$el
      });

      me.view.parent = me;
    },

    setState: function(state)
    {
      var me = this;

      me.state = me.state || {};
      var name = state._rest;
      var entity = state.entity;

      if( me.state._rest == name ) return;

      me.state = state;
      ////////////////////////////FIXME//////////////////////
      if ( !me.collection.get( name.substr(1) ) ) {
        var MyModel = Backbone.ModelEx.extend({
          idAttribute: "tag",
          url: darsan.makeUrl("", "darsan", '/worker' + name)
        });

        var model = new MyModel();

        model.fetch()
          .done(function(){
            if (model) {
              darsan.get("", "darsan", "/permission-tag")
                .then(function(channels)
                {
                  me.permissionsTag = me.options.permissionsTag = channels;

                  me.view.model = model;
                  me.view.render();
                });

            } else {
              // тут надо вывести сообщение, что такой модели нет
              // прямо на экран вместо редактора
              // или прямо во view Это предусмотреть
            }
          })
          .fail(function(m, errXHR){
            var div = common.alertDiv(darsan.errorText(errXHR), {type: "error"});
            me.view.$el.html( div );
          });
      }else{
        var model = me.collection.get( name.substr(1) );
        if (model) {
          darsan.get("", "darsan", "/permission-tag")
            .then(function(channels)
            {
              me.permissionsTag = me.options.permissionsTag = channels;

              me.view.model = model;
              me.view.render();
            });

        } else {
          // тут надо вывести сообщение, что такой модели нет
          // прямо на экран вместо редактора
          // или прямо во view Это предусмотреть
        }
      }

    }
  });
});