<template>
  <Tabs :tabs=workerTabs :state=state />
</template>

<script>
import Tabs from "common/visual/Tabs.vue"

export default {
  name: "WorkerPanel",
  components: {Tabs},

  props: {
    modus: Object,
  },
  
  data()
  {
    return {
      workerTabs: [
        { modPath: 'worker/page/WorkerEditor', name: "workerEdit", text: "Редактор", defaultTab: true, subpath: "" },
        //{ modPath: 'worker/program/worker-program', text: "Программы", name: "workerProg", defaultTab: true, subpath: "programs" },
        { modPath: 'worker/ftag/worker-ftag', text: "Права", name: "workerPerm", defaultTab: true, subpath: "permissions" },
      ],
      
      state: {
        _rest: "",
        _tail: "",
        subpath: "",
        uid: "",
      },
    }
  },
  
  created()
  {
    this.setState()
  },
  
  methods: {
    setState()
    {
      const path = this.modus.path
      const params = this.modus.params

      const s = {}

      const parts = path.split("/")
      s._rest = "/" + parts.slice(3).join("/")

      s.entity = parts[2] || ""
      s.subpath = parts[3] || ""
      s._tail = parts[4] || ""

      for (const p of new URLSearchParams(params))
      {
        s[ p[0] ] =  p[1]
      }
      
      this.state = s
    },        
    
  },
  
  watch: {
    modus(val) { this.setState() },
  },
}
</script>
