var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "prog",
        class: { "bg-info": _vm.enabled },
        attrs: { title: _vm.data.description },
        on: { click: _vm.toggle }
      },
      [_vm._v(_vm._s(_vm.data.name_ru))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }