<template>
<div>
  <div class="prog" @click=toggle :title=data.description :class="{'bg-info': enabled}" >{{data.name_ru}}</div>
</div>
</template>

<script>
export default {
  name: "ProgSwitch",
  
  props: {
    data: {
      type: Object,
      required: true,
    },
    value: Boolean,
    enabled: Boolean
  },
  
  methods: {
    toggle()
    {
      this.$emit("toggle", this.data.name)
    },
  },
}
</script>

<style scoped>
.prog
{
  border: 1px solid gray;
  padding: 0.5em;
  margin: 0.5em;
  cursor: pointer;
  width: 15em;
  height: 4em;
}

.prog.bg-info
{
  font-weight: bold;
}
</style>