<template>
  <keep-alive>
    <component :is=currentComponent :modus=currentModus />
  </keep-alive>
</template>

<script>
import WorkerNew from "worker/page/WorkerNew.vue"
import WorkerTable from "worker/page/WorkerTable.vue"
import WorkerPanel from "worker/page/WorkerPanel.vue"

import {eventBus} from "main"

export default {
  name: "WorkerPage",
  title: "Работники",
  components: {},
  
  data()
  {
    return {
      currentComponent: null,
      currentModus: {path: null, params: null},
    }
  },
  
  created()
  {
    eventBus.on("routeChanged", ({path,params}) => this.setState(path,params))
  },
  
  methods: {
    setState(path,params)
    {
      if (path.match(/\/worker\/[\w$_]+/))
      {
        this.currentComponent = WorkerPanel
      }
      else if (path=="/worker/!new")
      {
        this.currentComponent = WorkerNew
      }
      else
      {
        this.currentComponent = WorkerTable
      }
      
      this.currentModus = {path, params}
    },
  },

}

</script>
