<template>

<SearchTable ref=table name="worker" title="Работники" :columns=columns dynamic 
 apiTopic=darsan apiPath="/worker?paged=1" 
 metaApiTopic="darsan" metaApiPath="/meta/worker/search" 
 :rowClass=rowClass quickSearch=entity
 :toolbar=toolbar :state=tableState
 @toolbar-click=toolbarClick
>
  <template #title="{rec}">
    {{rec.title.join("")}}
  </template>

  <template #avatar="{rec}">
    <img class="avatar img-circle" :src=avatarURL(rec) /> 
  </template>

  <template #entity="{rec}">
    <a :href="'/worker/'+rec.entity">{{rec.entity}}</a>
  </template>
  
  <template #disabled="{rec}">
    <template v-if="rec.disabled"><Icon name=minus /></template>
    <template v-else>&#10003;</template>
  </template>

</SearchTable>
</template>
  
<script>

import darsan from "darsan"
import {eventBus} from "main"
import {goto} from "navigation"

export default {

    name: "WorkerTable",
    
    components: {},
  
    props: {
      modus: Object,
    },
    
    data()
    {
      return {
        api: API,
      
        ready: false,

        toolbar: [
          {
            name: "add",
            icon: "plus",
            text: "Добавить",
          },
        ],
        
        columns: [
          {
            name: "avatar",
            title: "",
          },
          {
            name: "entity",
            title: "Логин",
            sort: true,
          },
          {
            name: "disabled",
            title: "Статус",
            sort: true,
          },
          {
            name: "surname",
            title: "Фамилия",
            sort: true,
          },
          {
            name: "name",
            title: "Имя",
            sort: true,
          },
          {
            name: "phone",
            title: "Телефон",
            sort: true,
          },
          {
            name: "sip",
            title: "SIP",
            sort: true,
          },
          {
            name: "title",
            title: "Должность",
          },
        ],
        
      }
    },
    
    methods: {
      rowClass(rec) {
        if (rec.disabled) return "warning"
      },

      avatarURL (rec) {
        return API + '/avatar/' + rec.entity
      },
      
      toolbarClick({from}) {
        if (from == "add") {
          goto("/worker/!new")
        }
      },
    },
    
    computed: {
      tableState() {
        return this.modus.params
      }
    },
  }
</script>

<style scoped>
  .avatar {
    height: 20px;
    width: 20px;
  }
</style>