var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ready
        ? _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-9" }, [
              _c(
                "form",
                {
                  staticClass: "form-horizontal",
                  attrs: { autocomplete: "off" }
                },
                [
                  _c("input", {
                    staticClass: "hidden",
                    attrs: { name: "username" }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "hidden",
                    attrs: { name: "password", type: "password" }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "hidden",
                    attrs: { name: "telegram_id" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Select", {
                        attrs: {
                          label: "Статус",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.disabled,
                          options: _vm.disabledOptions
                        },
                        on: {
                          input: function($event) {
                            _vm.err.disabled = null
                          }
                        },
                        model: {
                          value: _vm.model.disabled,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "disabled", $$v)
                          },
                          expression: "model.disabled"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          label: "Фамилия",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.surname
                        },
                        on: {
                          input: function($event) {
                            _vm.err.surname = null
                          }
                        },
                        model: {
                          value: _vm.model.surname,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "surname", $$v)
                          },
                          expression: "model.surname"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          label: "Имя",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.name
                        },
                        on: {
                          input: function($event) {
                            _vm.err.name = null
                          }
                        },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Input", {
                        attrs: {
                          label: "Логин",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.entity
                        },
                        on: {
                          input: function($event) {
                            _vm.err.entity = null
                          }
                        },
                        model: {
                          value: _vm.model.entity,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "entity", $$v)
                          },
                          expression: "model.entity"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          label: "Пароль",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.password
                        },
                        on: {
                          input: function($event) {
                            _vm.err.password = null
                          }
                        },
                        model: {
                          value: _vm.model.password,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "password", $$v)
                          },
                          expression: "model.password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Select", {
                        attrs: {
                          label: "Должность",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.titleId,
                          options: _vm.titleOptions,
                          "with-blank": ""
                        },
                        on: {
                          input: function($event) {
                            _vm.err.titleId = null
                          }
                        },
                        model: {
                          value: _vm.model.titleId,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "titleId", $$v)
                          },
                          expression: "model.titleId"
                        }
                      }),
                      _vm._v(" "),
                      _c("Select", {
                        attrs: {
                          label: "Доступ к устройствам",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.device_access,
                          options: _vm.devAccessOptions
                        },
                        on: {
                          input: function($event) {
                            _vm.err.device_access = null
                          }
                        },
                        model: {
                          value: _vm.model.device_access,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "device_access", $$v)
                          },
                          expression: "model.device_access"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Input", {
                        attrs: {
                          label: "Телефон",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.phone
                        },
                        on: {
                          input: function($event) {
                            _vm.err.phone = null
                          }
                        },
                        model: {
                          value: _vm.model.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "phone", $$v)
                          },
                          expression: "model.phone"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          label: "SIP",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.sip
                        },
                        on: {
                          input: function($event) {
                            _vm.err.sip = null
                          }
                        },
                        model: {
                          value: _vm.model.sip,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "sip", $$v)
                          },
                          expression: "model.sip"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          label: "Telegram ID",
                          size: 7,
                          "label-size": 2,
                          error: _vm.err.telegram_id
                        },
                        on: {
                          input: function($event) {
                            _vm.err.telegram_id = null
                          }
                        },
                        model: {
                          value: _vm.model.telegram_id,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "telegram_id", $$v)
                          },
                          expression: "model.telegram_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.submit }
                    },
                    [
                      _c("Icon", { attrs: { name: "save" } }),
                      _vm._v(" Сохранить")
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        : _vm._l(9, function(i) {
            return _c("SkelInput", { key: i })
          })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-3 text-center" }, [
      _c(
        "span",
        {
          staticClass: "btn btn-default btn-file",
          staticStyle: { "border-color": "transparent" }
        },
        [
          _c("img", {
            staticClass: "img-circle",
            staticStyle: {
              "max-width": "120px",
              "webkit-box-shadow": "1px -1px 5px 1px rgba(0,0,0,0.75)",
              "-moz-box-shadow": "1px -1px 5px 1px rgba(0,0,0,0.75)",
              "box-shadow": "1px -1px 5px 1px rgba(0,0,0,0.75)"
            },
            attrs: { src: "/avatar/!new" }
          }),
          _vm._v(" "),
          _c("form", { attrs: { action: "#" } }, [
            _c("input", { attrs: { type: "file", name: "image", id: "image" } })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }