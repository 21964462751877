var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "1em" } },
    [
      _c("div", { staticClass: "col-md-3 text-center" }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", [
            _c("a", { attrs: { href: `/worker/${this.state.entity}` } }, [
              _vm._v(_vm._s(this.state.entity))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              { attrs: { href: `/worker/${this.state.entity}/permission` } },
              [_vm._v("Права")]
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.rec.name))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-group-vertical",
            attrs: { role: "group", "aria-label": "..." }
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-default",
                attrs: { href: `/history/worker/${this.state.entity}` }
              },
              [_vm._v("История изменений")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-default text-danger",
                on: { click: _vm.deleteTag }
              },
              [
                _c("Icon", { attrs: { name: "trash" } }),
                _vm._v(" Удалить\n          ")
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.ready
        ? _c("div", { staticClass: "col-md-9" }, [
            _c("div", { staticClass: "form-horizontal" }, [
              _c(
                "div",
                { staticClass: "well well-sm" },
                [
                  _c("div", { staticClass: "form-group has-feedback" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "tag" }
                      },
                      [_vm._v("Право")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c("p", { staticClass: "form-control-static" }, [
                        _vm._v(_vm._s(_vm.rec.name_ru))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "tag" }
                      },
                      [_vm._v("Описание")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c("p", { staticClass: "form-control-static" }, [
                        _vm._v(_vm._s(_vm.rec.description))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.rec.type == "string"
                    ? _c("Input", {
                        attrs: {
                          label: "Значение",
                          size: 7,
                          "label-size": 2,
                          name: _vm.rec.name,
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          info: _vm.rec.info,
                          save: _vm.save
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rec.type == "number"
                    ? _c("Number", {
                        attrs: {
                          label: "Значение",
                          size: 7,
                          "label-size": 2,
                          name: _vm.rec.name,
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          info: _vm.rec.info,
                          min: _vm.rec.min,
                          max: _vm.rec.max,
                          step: _vm.rec.step,
                          save: _vm.save
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rec.type == "oneof"
                    ? _c("Select", {
                        attrs: {
                          label: "Значение",
                          size: 7,
                          "label-size": 2,
                          name: _vm.rec.name,
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          info: _vm.rec.info,
                          options: _vm.oneOfOptions,
                          save: _vm.save
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rec.type == "darsan-oneof"
                    ? _c("Select", {
                        attrs: {
                          label: "Значение",
                          size: 7,
                          "label-size": 2,
                          name: _vm.rec.name,
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          info: _vm.rec.info,
                          options: _vm.darsanOneOfOptions,
                          save: _vm.save
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rec.type == "darsan-array"
                    ? _c("Select2", {
                        attrs: {
                          multiple: "",
                          label: "Значение",
                          size: 7,
                          "label-size": 2,
                          name: _vm.rec.name,
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          info: _vm.rec.info,
                          options: _vm.darsanArrayOptions,
                          save: _vm.save
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rec.type == "boolean"
                    ? _c("Select", {
                        attrs: {
                          label: "Значение",
                          size: 7,
                          "label-size": 2,
                          name: _vm.rec.name,
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          info: _vm.rec.info,
                          options: _vm.yesNoOptions,
                          save: _vm.save
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        : _vm._l(9, function(i) {
            return _c("SkelInput", { key: i })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }