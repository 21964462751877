<template>
<div>
  <div v-if="ready" class="row" style="padding-top: 1em">
    <div class="col-md-3 text-center">
        <ol class="breadcrumb">
            <li><span class="glyphicon glyphicon-th-list" aria-hidden="true"></span>&nbsp;&nbsp;<a href="/worker">Список работников</a></li>
            <li class="active">{{state.entity}}</li>
        </ol>

	<span class="btn btn-default btn-file" style="border-color: transparent;">
	    <img :src=avatarURL class="img-circle" style="max-width: 120px;  -webkit-box-shadow: 1px -1px 5px 1px rgba(0,0,0,0.75);-moz-box-shadow: 1px -1px 5px 1px rgba(0,0,0,0.75); box-shadow: 1px -1px 5px 1px rgba(0,0,0,0.75);">
	    <form action="#">
               <input type="file" name="image" id="image" @change=changeImage />
            </form>
	</span>

        <br><br>

        <div class="btn-group-vertical" role="group" aria-label="...">
            <a class="btn btn-default" :href="'/history/admin/'+this.state.entity">История действий</a>
            <a class="btn btn-default" :href="'/history/worker/'+this.state.entity">История изменений</a>
            <a class="btn btn-default text-danger" @click=deleteWorker >
                <Icon name=trash /> Удалить
            </a>
        </div>
    </div>

    <div class="col-md-9">

        <form class="form-horizontal">
          <!-- Hack for auto fill input password -->
          <input name="username" class="hidden">
          <input name="password" type="password" class="hidden">
          <input name="telegram_id" class="hidden">
          <!-- hack end -->

            <div class="well well-sm">
                <Select :size=7 :label-size=2 label="Статус" v-model="model.disabled" name=disabled apiTopic=darsan :apiPath=apiPath 
                 :options=disabledOptions />
                <Input :size=7 :label-size=2 label="Фамилия" v-model="model.surname" name=surname apiTopic=darsan :apiPath=apiPath />
                <Input :size=7 :label-size=2 label="Имя" v-model="model.name" name=name apiTopic=darsan :apiPath=apiPath />
            </div>

            <div class="well well-sm">
                <Input :size=7 :label-size=2 label="Новый пароль" v-model="model.password" name=password apiTopic=darsan :apiPath=apiPath />
            </div>

            <div class="well well-sm">
                <Select :size=7 :label-size=2 label="Должность" v-model="model.titleId" name=titleId apiTopic=darsan :apiPath=apiPath 
                 :options=titleOptions :save=saveTitle />

                <Select :size=7 :label-size=2 label="Доступ к устройствам" v-model="model.device_access" name=device_access apiTopic=darsan :apiPath=apiPath 
                 :options=devAccessOptions />
            </div>

            <div class="well well-sm" >
                <Input :size=7 :label-size=2 label="Телефон" v-model="model.phone" name=phone 
                 apiTopic=darsan :apiPath=apiPath :validate=checkPhone />
                <Input :size=7 :label-size=2 label="SIP" v-model="model.sip" name=sip apiTopic=darsan :apiPath=apiPath />
                <Input :size=7 :label-size=2 label="Telegram ID" v-model="model.telegram_id" name=telegram_id apiTopic=darsan :apiPath=apiPath />
            </div>
      </form>
    </div>
  </div>
  
  <template v-else>
    <SkelInput v-for="i in 9" :key=i />
  </template>
</div>
</template>

<script>

import Select from "common/visual/cooper/Select.vue"
import Input from "common/visual/cooper/Input.vue"
import SkelInput from "common/visual/SkelInput.vue"
import {notifyHttpError} from "common"
import {goto} from "navigation"
import darsan from "darsan"

export default {
  name: "WorkerEditor",
  
  components: {Select, Input, SkelInput},

  props: {
    state: Object,
  },
  
  created()
  {
    darsan.get("", "darsan", "/title").then(list => 
    {
      this.titles = list
      this.ready = true
    })
    .catch(res => notifyHttpError(res))
  },
  
  data()
  {
    return {
      ready: false,
      titles: [],
      model: {},
      err: {},
    }
  },
  
  activated()
  {
    darsan.get("", "darsan", this.apiPath)
    .then(rec => 
    {
      for (const name of Object.keys(rec))
      {
        if (name=="disabled")
        {
          this.$set(this.model, name, rec.disabled ? "true" : "")
        }
        else if (name=="titleId")
        {
          this.$set(this.model, name, rec.titleId[0])
        }
        else
        {
          this.$set(this.model, name, rec[name])
        }
      }
    })
  },
  
  methods: {
    checkPhone(val)
    {
      if (!val.match(/^\d{10}$/)) return "Неправильный формат номера. Введите 10 цифр"
    },
    saveTitle(val)
    {
      return darsan.put("", "darsan", this.apiPath+"/title-id", {titleId: val})
    },
    
    deleteWorker()
    {
      if (confirm("Вы уверены, что хотите удалить работника "+ this.state.entity + " ?"))
      {
        darsan.delete("", "darsan", this.apiPath)
          .then( () => 
          {
            this.$store.commit("refreshTable", "workers")
            goto("/worker")
          })
          .catch(xhr => notifyHttpError(xhr))
      }
    },
    
    changeImage(ev)
    {
      var me = this;

      function readURL(input, img) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $(img).attr('src', e.target.result);
              };

              reader.readAsDataURL(input.files[0]);
            }
      }

      var formData = new FormData();
      formData.append('image', ev.target.files[0]);

          darsan
            .formDataPut('', 'darsan', `/worker/${me.state.entity}/avatar`, formData)
            .done(function () {
              readURL(
                ev.target,
                $(ev.target)
                  .parent()
                  .prev()
              );
            });
    },
  },
  
  computed: {
    apiPath()
    {
      return "/worker/"+this.state.entity
    },

    avatarURL () {
      return API + '/avatar/' + this.state.entity
    },

    disabledOptions()
    {
      return [
        {value: "true", text: "Отключен"},
        {value: "", text: "Активен"},
      ]
    },

    devAccessOptions()
    {
      return [
        {value: "", text: "Нет"},
        {value: "read-only", text: "Чтение"},
        {value: "read-write", text: "Полный"},
      ]
    },
    
    titleOptions()
    {
      return this.titles.map(el => { return {text: el.name, value: el.entity} })
    },
  },
  
  watch: {
    model: {
      deep: true, 
      handler(val)
      {
        this.$store.commit("refreshTable", "workers")
      }
    }
  },
}
</script>
