define([
    'common/visual/layout',
    'darsan',

    'text-loader!worker/ftag/layout.tpl',
    "worker/tag/worker-tag",

    "common/search/search"
], function(Layout, darsan, Template, listMod, Search) {
    'use strict';
    var searchMod = Object.create(Search).extend({
        metaAddress: ["", "darsan", "/meta/permission/search"],
    });

    return Object.create(Layout).extend({
        template: Template,
        modParams: {},

        layout: {
            search: searchMod,
            tag_list: listMod
        },

        create: function(el,options)
        {
            var me = this;
            this.modParams.collection = options.collection;
            this.modParams.permissionsTag= options.permissionsTag;

            return darsan.get("", "darsan", "/permission-tag")
              .then(function(channels) {
                  me.permissionsTag = me.modParams.permissionsTag = channels;
                  return Layout.create.call(me,el,options);
              });
        }
    });
});
