var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "keep-alive",
    [
      _c(_vm.currentComponent, {
        tag: "component",
        attrs: { state: _vm.state, id: _vm.state._tail }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }