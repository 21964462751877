define([
    'common/visual/layout',
    'text-loader!title/fedit/layout.tpl',
    "worker/edit/worker-edit",
    "worker/program/worker-program",
    "worker/ftag/worker-ftag",

], function(Layout, Template, editMod, programMod, tagMod)
{

    return Object.create(Layout).extend({
        template: Template,
        modParams: {},

        layout: {
            titletab: [
                {name: "edit", module: editMod, title: "Редактор", text: "Редактор"},
                {name: "programs", module: programMod, title: "Программы", text: "Программы"},
                {name: "rights", module: tagMod, title: "Права", text: "Права"},
            ]
        },

        create: function(el, options)
        {
            this.modParams.collection = options.collection;
            this.modParams.permissions= options.permissions;

            return Layout.create.call(this, el, options);
        },

        neededOverlay: function(state, name)
        {
            if (name !== "titletab") throw "No overlay for " + name;
            return state.list_query != null ? "rights" : "edit";
        }

    });
});
