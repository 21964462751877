var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SearchTable", {
    ref: "table",
    attrs: {
      name: "worker-perm",
      title: _vm.breadCrumb,
      columns: _vm.columns,
      dynamic: "",
      apiTopic: "darsan",
      apiPath: _vm.apiPath,
      metaApiTopic: "darsan",
      metaApiPath: "/meta/permission/search",
      toolbar: _vm.toolbar,
      fetch: _vm.fetchRows,
      state: _vm.query
    },
    on: { "toolbar-click": _vm.toolbarClick },
    scopedSlots: _vm._u([
      {
        key: "name",
        fn: function({ rec }) {
          return [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "/worker/" +
                    _vm.state.entity +
                    "/permissions/" +
                    rec.tag.replace(/\./g, ":")
                }
              },
              [_vm._v(_vm._s(_vm.$store.getters.getPermTag(rec.tag).name_ru))]
            )
          ]
        }
      },
      {
        key: "type",
        fn: function({ rec }) {
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$store.getters.getPermTag(rec.tag).type) +
                "\n  "
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }