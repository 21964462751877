define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common/visual/visual',
  'navigation',
  'common',
  'radio',

  'text-loader!worker/tag-new/layout.tpl',

], function($, _, Backbone, darsan, visual, navigation, common, radio,
            titleAddTemplate) {
  'use strict';
  return Object.create(visual).extend({

    create: function(el,opt)
    {
      var me = this;
      visual.create.apply(me,arguments);

      var WorkerView = Backbone.TemplateView.extend({
        events: {
          'change #tag': 'typeOfAddTag',

          'click .submit-title': "submitTitle"
        },
        template: titleAddTemplate,


        typeOfAddTag: function()
        {
          var permTag =  _.indexBy(me.options.permissionsTag, 'name');

          var inputTags = {
            'string': $("<input />", {type: 'text'}),
            'number': $("<input />", {type: 'number', step: 'any'}) ,
            'boolean': $('<select />').append($("<option />", {value: 1}).html("да")).append($("<option />", {value: 0}).html("нет")),
            'oneof': $("<select />"),
            'darsan-oneof': $("<select />"),
            'darsan-array': $("<select multiple='multiple' />")

          };

          var valSelect = this.$el.find("#tag").val();
          var typeTag = permTag[valSelect].type;
          var inputTag = inputTags[typeTag];

          inputTag.attr('id', 'value');
          inputTag.addClass('form-control');

          if(typeTag === 'darsan-oneof' || typeTag === 'darsan-array')
          {
            darsan.get(permTag[valSelect].prefix, permTag[valSelect].topic, permTag[valSelect].path, "")
              .done(function(data){
                _.each( data, function(val){
                  inputTag.append( $("<option />", {value: val.entity}).html(val.display_name));
                });
                if (typeTag === 'darsan-array') {
                  inputTag.append( $("<option />", {value: '*'}).html("Все"));
                }
              });
          }

          if(typeTag === 'oneof')
          {
            _.each( permTag[valSelect].values, function(val){
              inputTag.append( $("<option />", {value: val}).html(val));
            });
          }

          var oldInput = this.$el.find("#value");
          this.$el.find("#value").parent().prepend(inputTag);

          this.$el.find("#tag-dsc").html(permTag[valSelect].description || '');

          oldInput.select2('destroy');
          oldInput.remove();
          this.$el.find("select#value").select2();
        },

        submitTitle: function(ev) {
          var _this = this;

          var tagId = this.$el.find('#tag').val();
          var tagVal = {value: this.$el.find('#value').val(), tag: tagId};

          _this.model.url = _this.model.urlRoot + '/' + tagId;

          var ownersChannel = radio.channel('owners');

          opt.collection.add(_this.model);
          _this.model.save(tagVal, {type: "PUT"})
            .done(function (model, newNas) {
               _this.$el.find('#tag').select2('destroy');
               _this.$el.find("option[value='" + tagId + "']").remove();
               _this.$el.find('#tag').select2();
               _this.typeOfAddTag();

              common.notify("Право добавлено!", {} );

              ownersChannel.trigger('module:worker-tag:change');

              navigation.changeState( { _rest: "/" + me.state._rest.split("/")[1], titletab: "rights"} );
            })
            .fail(function (view, errorXHR) {
              const [name, text] = darsan.namedError(errorXHR)
              if(name){
                _this.$el.find("[name='"+name+"']").closest(".form-group").addClass("has-error");
                _this.$el.find("[name='"+name+"']").focus();
                _this.$el.find("#help-"+name).removeClass("hidden").html(text);
              }
            });
        },

        initialize: function()
        {
          this.compiled = _.template(this.template,null);
        },

        render: function()
        {
          var _this = this;

          darsan.get("", "darsan", "/worker/" + me.titleId + '/permission', "")
            .done(function(data){
              var exists = _.pluck(data, 'tag');
              var evens = _.filter(me.options.permissionsTag, (val) => _.indexOf(exists, val.name) == -1);

              var markup = _this.compiled( {me: _this.model.toJSON(), permTag: evens, entity: me.titleId } );
              _this.$el.html(markup);

              _this.$el.find("#tag").select2();

              _this.typeOfAddTag();
              console.log(_this);
              return _this;
            });
        }
      });

      me.view = new WorkerView({
        el: me.$el
      });
    },

    setState: function(state)
    {
      var me = this;

      var entity = state._rest;
      me.titleId = state._rest.match("^\/([^\/]+)\/")[1];
      me.state = me.state || {};

      if( me.state._rest == entity ) return;

      me.state = state;

      var url = darsan.makeUrl("", "darsan", '/worker/' + me.titleId + "/permission");

      var MyModel = Backbone.ModelEx.extend({
        idAttribute: "tag",
        urlRoot: url
      });

      var model = new MyModel();

      darsan.get("", "darsan", "/permission-tag")
        .then(function(channels)
        {
          me.permissionsTag = me.options.permissionsTag = channels;

          me.view.model = model;
          me.view.render();
        });
    }
  });
});