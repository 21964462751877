var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-top": "1em" } },
    [
      _vm.ready
        ? _c(
            "table",
            _vm._l(5, function(i) {
              return _c(
                "div",
                { staticClass: "row" },
                _vm._l(6, function(j) {
                  return _c(
                    "div",
                    { staticClass: "col-sm-2" },
                    [
                      _vm.prog(i, j)
                        ? _c("ProgSwitch", {
                            attrs: {
                              data: _vm.prog(i, j),
                              enabled: _vm.enabled[_vm.prog(i, j).name]
                            },
                            on: { toggle: _vm.toggle }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            }),
            0
          )
        : _vm._l(5, function(i) {
            return _c(
              "div",
              { staticClass: "row" },
              _vm._l(6, function(j) {
                return _c(
                  "div",
                  { staticClass: "col-sm-2" },
                  [_c("SkelLabel", { staticStyle: { height: "4em" } })],
                  1
                )
              }),
              0
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }