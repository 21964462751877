define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'navigation',
  "common/table/table-view",
  "common/visual/visual",

  'text-loader!worker/list/layout.tpl',
  'text-loader!worker/list/row.tpl',
  'text-loader!worker/list/table.tpl',
  
], function($, _, Backbone, darsan, common, navigation, TableView, visual,
            workerListTemplate, rowTemplate, tableTemplate)
{
  var workerList = Object.create(visual).extend({
    parent: null,
    searchName: "",

    create: function(el,opt)
    {
      var me = this;
      visual.create.apply(me,arguments);

      me.renderFromTemplate(workerListTemplate, {perm: me.options.permissions});

      me.$el.find("#quick-search").keyup(function() {
        function onKeyUp() { navigation.changeState( { list_quick: me.$el.find("#quick-search").val()} ); };
        me.$el.find("#quick-search").keyup(_.debounce(onKeyUp, 300));
      });

      me.pager = new Backbone.PagerView({
            el: me.$el.find("#worker-pagination"),
            collection: me.collection
      });

      me.pager.on("setPage", function(page) { navigation.changeState({ list_page: page }) });

      var table = new TableView(
      {
        el: me.$el.find("div.table-responsive"),
        collection: me.collection,
        rowTemplate: rowTemplate,
        tableTemplate: tableTemplate,
        data: { perm: me.options.permissions }
      });

      table.on("setSort",function(sort) { navigation.changeState({list_sort: sort}); });
 
      table.on("edit",function(model)
      {
        if (me.options.permissions.can_manage_titles || model.get( "entity" ) == user.login)
        {
          navigation.changeState({
            _rest: "/"+model.get( "entity" ), 
            list_page: null, list_query: null
          });
        }
      });

      table.render();
    },
    
    setState: function(state)
    {
      var me = this;

      if (!(state.list_box || state.list_query)) me.searchName = "";

      if (me.searchName) {
        me.$el.find("#search-name").empty().append("("+me.searchName+")");
      } else {
        me.$el.find("#search-name").empty().append("");
      }
      
      var st = common.filterState(state,"list");

      if (me.state!==null && _.isEqual(me.state, st)) return;
      me.state = st;
      
      var data = {};
      
      if (me.state.list_box)
      {
        data.box = me.state.list_box;
      }

      if (me.state.list_query)
      {
        delete data.box; 
        data.query = me.state.list_query;
      }
      
      if (me.state.list_sort) 
      {
        data.sort = me.state.list_sort;
      }

      if(me.state.list_quick){
        delete data.list_box; 
        delete data.list_query; 
        data.quick_search = me.state.list_quick; 
        me.$el.find("#quick-search").val(me.state.list_quick);
      }
      
      data.page = me.state.list_page || 1;
      me.collection.fetch({reset: true, data: data});
    },

    setSearchName: function(str)
    {
      this.searchName = str;
    }
  });

  return workerList;
});
