define([
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'navigation',
    'common',

    'text-loader!worker/program/layout.tpl'

], function($, _, Backbone, darsan, visual, navigation, common, titleEditTemplate){

    ///////////////////////////////////////////////////////////

    return Object.create(visual).extend({

        parent: null,

        create: function(el, opt)
        {
            var me = this;

            visual.create.apply(me,arguments);

            // console.log("title.list.create in",el.attr("id"),opt);

            var ChannelsView = Backbone.TemplateCooperView.extend({
                events: {
                    'change input[type="checkbox"]': 'changeAllow',
                    'click #testEv1': 'test'
                },

                template: titleEditTemplate,

                changeAllow: function(ev)
                {
                    var $input = $(ev.target);
                    var allowed = ev.target.checked;
                    var tagID = $input.attr("data-id");

                    var existsTag = _.indexBy(me.existsTag.toJSON(), 'tag');

                    if(existsTag[tagID]){
                        darsan.patch("","darsan","/worker" + me.state._rest + "/permission/" + tagID, {value: allowed })
                            .done(function(){
                                $input.closest(".panel").toggleClass("panel-success").toggleClass("panel-danger");
                            })
                    }else{
                        darsan.put("","darsan","/worker" + me.state._rest + "/permission/" + tagID, {value: 1 })
                            .done(function(){
                                $input.closest(".panel").toggleClass("panel-success").toggleClass("panel-danger");

                                me.existsTag.add([
                                    {tag: tagID, value: 1}
                                ]);
                            })
                    }
                },

                render: function()
                {
                    var _this = this;

                    var coll = _.map(this.collection.toJSON(), function(entity){ return entity.id; });

                    var existsTag = _.indexBy(me.existsTag.toJSON(), 'tag');

                    var markup = this.compiled( {programs: this.collection.toJSON(), existsTag: existsTag, entity: me.entityId } );
                    this.$el.html(markup);

                    return this;
                },

            }); // WorkerView

            me.view = new ChannelsView({
                el: me.$el,
            });

            me.view.parent = me;
        },

        setState: function(state)
        {
            var me = this;
            // console.log("title.list.setState",state);

            me.state = me.state || {};
            var name = state._rest;

            if( me.state._rest == name ) return;

            me.state = state;

            me.entityId = state._rest.split('/')[1];

            var ProgramModel = Backbone.ModelEx.extend({
                idAttribute: "name"
            });
            //http://dev.mol.net.ua:22002/permission-tag?query=name+LIKE+%22*.allowed%22&paged=1&from=0&count=25
            url = darsan.makeUrl("", "darsan", '/permission-tag?query=name+LIKE+%22*.allowed%22');

            var ProgramList = Backbone.CollectionEx.extend({
                model: ProgramModel,
                url: url
            });

            var ExistsTagModel = Backbone.ModelEx.extend({
                idAttribute: "tag"
            });
            //http://dev.mol.net.ua:22002/worker/_test_disp/permission?query=tag+LIKE+%22*.allowed%22&page=1
            url = darsan.makeUrl("", "darsan", '/worker/' + me.entityId + '/permission?query=tag+LIKE+%22*.allowed%22');

            var ExistsTagList = Backbone.CollectionEx.extend({
                model: ExistsTagModel,
                url: url
            });


            ////////////////////////////FIXME//////////////////////

            var collection = new ProgramList();
            me.existsTag = new ExistsTagList();

            $.when(collection.fetch(), me.existsTag.fetch())
                .then(function(a,b){
                    if (collection){
                        me.view.collection = collection;

                        me.view.render();
                    }else {
                        // тут надо вывести сообщение, что такой модели нет
                        // прямо на экран вместо редактора
                        // или прямо во view Это предусмотреть
                    }
                })
        },

    });
});