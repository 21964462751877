<template>
  <keep-alive>
    <component :is=currentComponent :state=state :id=state._tail />
  </keep-alive>
</template>

<script>
import WorkerTagNew from "worker/ftag/WorkerTagNew.vue"
import WorkerTagTable from "worker/ftag/WorkerTagTable.vue"
import WorkerTagEditor from "worker/ftag/WorkerTagEditor.vue"

export default {
  name: "WorkerTagPage",
  title: "Теги прав",
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      currentComponent: null,
      
      toolbar: [
      ],
    }
  },
  
  activated()
  {
    this.setState()
  },
  
  methods: {
    setState()
    {
      if (this.state._tail=="!new")
      {
        this.currentComponent = WorkerTagNew
      }
      else if (this.state._tail)
      {
        this.currentComponent = WorkerTagEditor
      }
      else
      {
        this.currentComponent = WorkerTagTable
      }
    }
  },
  
  computed: {
  },
  
  watch: {
    state() { this.setState() },
  }

}

</script>
