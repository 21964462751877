var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ready
        ? _c(
            "div",
            { staticClass: "row", staticStyle: { "padding-top": "1em" } },
            [
              _c("div", { staticClass: "col-md-3 text-center" }, [
                _c("ol", { staticClass: "breadcrumb" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("li", { staticClass: "active" }, [
                    _vm._v(_vm._s(_vm.state.entity))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "btn btn-default btn-file",
                    staticStyle: { "border-color": "transparent" }
                  },
                  [
                    _c("img", {
                      staticClass: "img-circle",
                      staticStyle: {
                        "max-width": "120px",
                        "-webkit-box-shadow":
                          "1px -1px 5px 1px rgba(0,0,0,0.75)",
                        "-moz-box-shadow": "1px -1px 5px 1px rgba(0,0,0,0.75)",
                        "box-shadow": "1px -1px 5px 1px rgba(0,0,0,0.75)"
                      },
                      attrs: { src: _vm.avatarURL }
                    }),
                    _vm._v(" "),
                    _c("form", { attrs: { action: "#" } }, [
                      _c("input", {
                        attrs: { type: "file", name: "image", id: "image" },
                        on: { change: _vm.changeImage }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "btn-group-vertical",
                    attrs: { role: "group", "aria-label": "..." }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default",
                        attrs: { href: "/history/admin/" + this.state.entity }
                      },
                      [_vm._v("История действий")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default",
                        attrs: { href: "/history/worker/" + this.state.entity }
                      },
                      [_vm._v("История изменений")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default text-danger",
                        on: { click: _vm.deleteWorker }
                      },
                      [
                        _c("Icon", { attrs: { name: "trash" } }),
                        _vm._v(" Удалить\n            ")
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-9" }, [
                _c("form", { staticClass: "form-horizontal" }, [
                  _c("input", {
                    staticClass: "hidden",
                    attrs: { name: "username" }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "hidden",
                    attrs: { name: "password", type: "password" }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "hidden",
                    attrs: { name: "telegram_id" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Select", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Статус",
                          name: "disabled",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          options: _vm.disabledOptions
                        },
                        model: {
                          value: _vm.model.disabled,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "disabled", $$v)
                          },
                          expression: "model.disabled"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Фамилия",
                          name: "surname",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.surname,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "surname", $$v)
                          },
                          expression: "model.surname"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Имя",
                          name: "name",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Input", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Новый пароль",
                          name: "password",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.password,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "password", $$v)
                          },
                          expression: "model.password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Select", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Должность",
                          name: "titleId",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          options: _vm.titleOptions,
                          save: _vm.saveTitle
                        },
                        model: {
                          value: _vm.model.titleId,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "titleId", $$v)
                          },
                          expression: "model.titleId"
                        }
                      }),
                      _vm._v(" "),
                      _c("Select", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Доступ к устройствам",
                          name: "device_access",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          options: _vm.devAccessOptions
                        },
                        model: {
                          value: _vm.model.device_access,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "device_access", $$v)
                          },
                          expression: "model.device_access"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "well well-sm" },
                    [
                      _c("Input", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Телефон",
                          name: "phone",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath,
                          validate: _vm.checkPhone
                        },
                        model: {
                          value: _vm.model.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "phone", $$v)
                          },
                          expression: "model.phone"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "SIP",
                          name: "sip",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.sip,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "sip", $$v)
                          },
                          expression: "model.sip"
                        }
                      }),
                      _vm._v(" "),
                      _c("Input", {
                        attrs: {
                          size: 7,
                          "label-size": 2,
                          label: "Telegram ID",
                          name: "telegram_id",
                          apiTopic: "darsan",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.telegram_id,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "telegram_id", $$v)
                          },
                          expression: "model.telegram_id"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        : _vm._l(9, function(i) {
            return _c("SkelInput", { key: i })
          })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", {
        staticClass: "glyphicon glyphicon-th-list",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("  "),
      _c("a", { attrs: { href: "/worker" } }, [_vm._v("Список работников")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }