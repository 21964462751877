define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common/visual/visual',
  'navigation',
  'common',

  'text-loader!worker/edit/layout.tpl',

], function ($, _, Backbone, darsan, visual, navigation, common, workerEditTemplate) {
  'use strict';
  return Object.create(visual).extend({
    parent: null,

    create: function (el, opt) {
      var me = this;

      visual.create.apply(me, arguments);

      var WorkerView = Backbone.TemplateCooperView.extend({
        events: {
          'select2-selecting select.select2-multiple': 'selectingSelect2',
          'select2-removing select.select2-multiple' : 'removingSelect2',
          'click .undo-select2Multi'                 : 'undoSelect2Multi',

          'change input[type="file"]': 'imageChange',
          'input input[type="file"]': 'imageChange',
          'click #deleteWorker'      : 'deleteEntity',

          'click #testEv1': 'test'
        },

        navigation: navigation,
        common    : common,

        template: workerEditTemplate,

        deleteWorker: function (ev) {
          var me = this;

          if (confirm('Вы уверены, что хотите удалить - ' + this.model.id + '?')) {
            me.model
              .destroy()
              .done(function (model) {
                navigation.changeState({ _rest: '/' });
              })
          }
        },

        imageChange: function (ev) {
          var me = this;

          function readURL(input, img) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $(img).attr('src', e.target.result);
              };

              reader.readAsDataURL(input.files[0]);
            }
          }

          var formData = new FormData();
          formData.append('image', ev.target.files[0]);

          darsan
            .formDataPut('', 'darsan', `/worker/${me.model.id}/avatar`, formData)
            .done(function () {
              readURL(
                ev.target,
                $(ev.target)
                  .parent()
                  .prev()
              );
            });
        },

        removingSelect2: function (ev) {
          var me = this;

          var el   = $(ev.target);
          var name = $(ev.target).attr('name');

          var toDelete   = {};
          toDelete[name] = ev.val;

          var toOld   = el.val();
          var toInput = el.val();

          var index = toInput.indexOf(ev.val);
          if (index > -1) {
            toInput.splice(index, 1);
          }

          var el_animate = el.prev().children();

          darsan
            .delete('', 'darsan', '/worker/' + me.model.id + '/title-id', toDelete)
            .done(function () {
              el_animate
                .removeClass('animate_success')
                .addClass('animate_success')
                .delay(1000)
                .queue(function () {
                  $(this)
                    .removeClass('animate_success')
                    .dequeue();
                });

              el.attr('data-old-val', toOld.join(','));
              $("[data-undo='" + name + "']").removeClass('hidden');

              me.model.set('titleId', toInput);
            });
        },

        selectingSelect2: function (ev) {
          var me = this;

          var el = $(ev.target);

          var name    = el.attr('name');
          var toPut   = {};
          toPut[name] = ev.val;

          var toOld   = el.val() || [];
          var toInput = el.val() || [];

          toInput.push(ev.val);

          var el_animate = el.prev().children();

          darsan.put('', 'darsan', '/worker/' + me.model.id + '/title-id', toPut).done(function () {
            el_animate
              .removeClass('animate_success')
              .addClass('animate_success')
              .delay(1000)
              .queue(function () {
                $(this)
                  .removeClass('animate_success')
                  .dequeue();
              });

            el.attr('data-old-val', toOld.join(','));
            $("[data-undo='" + name + "']").removeClass('hidden');

            me.model.set('titleId', toInput);
          });
        },

        undoSelect2Multi: function (el_undo, me) {
          me      = me || this;
          el_undo = $(el_undo);

          var el = me.$el.find('#' + el_undo.attr('data-undo'));

          var newTest = el.attr('data-old-val').split(',');
          var old     = el.val();

          var el_animate = el.prev().children();

          var diffToDel = _.difference(old, newTest);
          if (diffToDel.length > 0) {
            darsan
              .delete('', 'darsan', '/worker/' + me.model.id + '/title-id', {
                titleId: diffToDel
              })
              .done(function () {
                el_animate
                  .removeClass('animate_success')
                  .addClass('animate_success')
                  .delay(1000)
                  .queue(function () {
                    $(this)
                      .removeClass('animate_success')
                      .dequeue();
                  });

                el.attr('data-old-val', old.join(','));
                el_undo.addClass('hidden');

                el.select2('val', newTest);
                me.model.set('titleId', newTest);
              });
          }

          var diffToPut = _.difference(newTest, old);
          if (diffToPut.length > 0) {
            darsan.put('', 'darsan', '/worker/' + me.model.id + '/title-id', {
              titleId: _.difference(newTest, old)
            });

            el_animate
              .removeClass('animate_success')
              .addClass('animate_success')
              .delay(1000)
              .queue(function () {
                $(this)
                  .removeClass('animate_success')
                  .dequeue();
              });
            el.attr('data-old-val', old.join(','));
            el_undo.addClass('hidden');

            el.select2('val', newTest);
            me.model.set('titleId', newTest);
          }
        },

        render: function () {
          var _this = this;
          $.when(darsan.get('', 'darsan', '/title')).then(function (titles) {
            var markup = _this.compiled({
              me    : _this.model.toJSON(),
              perm  : me.options.permissions,
              titles: titles
            });

            _this.$el.html(markup);

            _this.$el.find('#titleId').select2({
              maximumSelectionSize: 1
            });

            var undo_el = $('<i />', {
              class      : 'undo-select2Multi form-control-feedback fa fa-reply hidden',
              style      : 'cursor: pointer; pointer-events: auto;',
              'data-undo': 'titleId'
            }).click(function () {
              _this.undoSelect2Multi(this, _this);
            });

            _this.$el
              .find('#s2id_titleId')
              .find('ul')
              .css('padding-right', '24px');
            _this.$el.find('#s2id_titleId').append(undo_el);

            return _this;
          });
        }
      }); // WorkerView

      me.view = new WorkerView({
        el: me.$el
      });

      me.view.parent = me;
    },

    setState: function (state) {
      var me = this;

      me.state   = me.state || {};
      // var entity = state._rest;
      var entity = state.entity;

      // if (me.state._rest == entity) return;

      me.state = state;

      var model;

      if (!me.collection.get(entity.substr(1))) {
        var url = darsan.makeUrl('', 'darsan', `/worker/${entity}`);

        var MyModel = Backbone.ModelEx.extend({
          idAttribute: 'entity',
          url        : url
        });

        model = new MyModel();

        model
          .fetch()
          .done(function () {
            if (model) {
              me.view.model = model;
              me.view.render();
            }
          })
          .fail(function (m, errXHR) {
            var div = common.alertDiv(darsan.errorText(errXHR), {
              type: 'error'
            });
            me.view.$el.html(div);
          });
      } else {
        model = me.collection.get(entity.substr(1));
        if (model) {
          me.view.model = model;
          me.view.render();
        }
      }
    }
  });
});
