define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'navigation',
  'radio',

  "common/table/table-view",
  "common/visual/visual",

  'text-loader!worker/tag/layout.tpl',
  'text-loader!worker/tag/row.tpl',
  'text-loader!worker/tag/table.tpl',

], function($, _, Backbone, darsan, common, navigation, radio,
            TableView, visual,
            titleListTemplate, rowTemplate, tableTemplate)
{
  'use strict';
  return Object.create(visual).extend({
    parent: null,
    searchName: "",

    create: function(el, opt)
    {
      var me = this;
      visual.create.apply(me,arguments);

      if (me.titleId) {
        me.titleName = me.parent.collection.get(me.titleId).attributes('name');
      }

      me.renderFromTemplate(titleListTemplate, {permTag: _.indexBy(me.options.permissionsTag, 'name'), titleName: me.titleName || ""});

      var url = darsan.makeUrl("", "darsan", '/worker' + name + '/permission');

      var TagModel = Backbone.ModelEx.extend({
        idAttribute: "tag",
        urlOrigin: url
      });

      var TagsCollection = Backbone.CollectionEx.extend({
        model: TagModel,
        url: url
      });

      me.collectionTag = new TagsCollection();

      me.$el.find("#quick-search").keyup(function() {
        function onKeyUp() { navigation.changeState( { list_quick: me.$el.find("#quick-search").val()} ); }
        me.$el.find("#quick-search").keyup(_.debounce(onKeyUp, 300));
      });

      me.pager = new Backbone.PagerView({
        el: me.$el.find("#title-pagination"),
        collection: me.collectionTag
      });

      me.pager.on("setPage",function(page) { navigation.changeState({list_page: page}); });

      var table = new TableView({
        el: me.$el.find("div.table-responsive"),
        collection: me.collectionTag,
        rowTemplate: rowTemplate,
        tableTemplate: tableTemplate,
        data: {titleId: me.titleId, permTag: _.indexBy(me.options.permissionsTag, 'name')}
      });

      table.on("setSort",function(sort) {
        navigation.changeState({list_sort: sort});
      });

      var ownersChannel = radio.channel('owners');
      ownersChannel.on('module:worker-tag:change', function() {
        me.collectionTag.fetch({ reset: true });
      });

      table.on("edit",function(model) {
        navigation.changeState({
          _rest: "/"+ me.titleId + "/permission/" + model.id,
          list_page: null, list_box: null, list_query: null
        });
      });

      table.render();
      me.table = table;
    },

    setState: function(state)
    {
      var me = this;
      var name = state._rest;
      var entity = state.entity;
      me.titleId = state.entity;

      me.table.data.titleId = me.titleId;

      me.$el.find("#add-entity").attr('href', `/worker/${entity}/permission/!new`);
      me.$el.find("#entity-name").html(me.titleId).attr('href', `/worker/${entity}`);

      me.collectionTag.url = darsan.makeUrl("", "darsan", `/worker/${entity}/permission`);

      if (!(state.list_box || state.list_query)) {
        me.searchName = "";
      }
      if (me.searchName) {
        me.$el.find("#search-name").empty().append("("+me.searchName+")");
      } else {
        me.$el.find("#search-name").empty().append("");
      }

      var st = common.filterState(state,"list");

      if (me.state !== null && _.isEqual(me.state, st)) return;
      me.state = st;

      var data = {};

      if (me.state.list_box) {
        data.box = me.state.list_box;
      }

      if (me.state.list_query) {
        delete data.box;
        data.query = me.state.list_query;
      }

      if (me.state.list_sort) {
        data.sort = me.state.list_sort;
      }

      if (me.state.list_quick){
        delete data.list_box;
        delete data.list_query;
        data.quick_search = me.state.list_quick;
        me.$el.find("#quick-search").val(me.state.list_quick);
      }

      data.page = me.state.list_page || 1;

      me.collectionTag.fetch({reset: true, data: data})
        .done(function(data, xhr){
          if (me.collectionTag){
            me.table.render();
          }else {
            // тут надо вывести сообщение, что такой модели нет
            // прямо на экран вместо редактора
            // или прямо во view Это предусмотреть
          }
        })
        .fail(function(m, errXHR){
          common.alertDiv(darsan.errorText(errXHR), {type: "error"});
        });

    },

    setSearchName: function(str)
    {
      this.searchName = str;
    }
  });
});
