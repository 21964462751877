define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common/visual/visual',
  'navigation',
  'common',

  'text-loader!worker/new/layout.tpl',
  
], function($, _, Backbone, darsan, visual, navigation, common, workerAddTemplate){
 

  ///////////////////////////////////////////////////////////

  return Object.create(visual).extend({
  
    create: function(el,opt)
    {
      var me = this;
      visual.create.apply(me,arguments);
                                              
      var WorkerView = Backbone.TemplateView.extend({
        events: {
          'click .submit-worker': "submitWorker",

          'change input[name="image"]' : "imageChange",
        },
        template: workerAddTemplate,

        imageChange: function(ev){

          function readURL(input, img) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $(img).attr('src', e.target.result);
              };

              reader.readAsDataURL(input.files[0]);
            }
          }

          readURL(ev.target, $(ev.target).parent().prev());
        },

        submitWorker: function(ev, two){
          var me = this;

          var form = $(ev.target).closest("form");

          if(!form[0].checkValidity())
          {
            form.find("[type='submit']").click();
            return ;
          }

          var entity = form.find("#entity").val();
          var formData = new FormData(form[0]);

          $(".help-block").addClass("hidden");
          $(".form-group").removeClass("has-error");

          darsan.formDataPut("","darsan","/worker/"+entity, formData).done(function(){

              // var url = location.origin + "/worker/"+entity;
              // url = "/worker/"+entity;
              var ava = me.$el.find("input[name='image']");

              common.notify("Работник создан!");

              if(ava[0].files.length){
                formDataImg = new FormData();
                formDataImg.append("image", ava[0].files[0]);

                darsan.formDataPut("","darsan","/worker/"+entity+"/avatar", formDataImg).done(function(){
                  // Backbone.history.navigate(url, {trigger: true});
                  
                  navigation.changeState( { _rest: "/" + entity } );
                });
              }else{
                // Backbone.history.navigate(url, {trigger: true});
                
                navigation.changeState( { _rest: "/" + entity } );
              }
            })
            .fail(function(errorXHR){
              const [name,text] = darsan.namedError(errorXHR)
              if(name){
                me.$el.find("[name='"+name+"']").closest(".form-group").addClass("has-error");
                me.$el.find("[name='"+name+"']").focus();
                me.$el.find("#help-"+name).removeClass("hidden").html(text);    
              }
            });
        },

        initialize: function()
        {
          this.compiled = _.template(this.template,null);
        },
                      
        render: function () {
          var _this = this;

          $.when(darsan.get('', 'darsan', '/title'))
          .then(function (titles) {
            var markup = _this.compiled({
              me: _this.model.toJSON(),
              perm: me.options.permissions,
              titles: titles
            });

            _this.$el.html(markup);

            _this.$el.find('#titleId').select2();

            var undo_el = $("<i />", {
              class: "undo-select2Multi form-control-feedback fa fa-reply hidden",
               style: "cursor: pointer; pointer-events: auto;",
               "data-undo": "titleId"
            })
            .click( function(){
              _this.undoSelect2Multi(this, _this);
            });

            _this.$el.find("#s2id_titleId").find("ul").css("padding-right", "24px");
            _this.$el.find("#s2id_titleId").append(undo_el);

            return _this;
          });

        }
      });

      me.view = new WorkerView({
        el: me.$el,
      });
    },
    
    setState: function(state)
    {
      var me = this;

      var entity = state._rest;
      me.state = me.state || {};

      if( me.state._rest == entity ) return;

      me.state = state;  
      
      var url = darsan.makeUrl("", "darsan", '/worker');

      var MyModel = Backbone.ModelEx.extend({
        idAttribute: "entity",
        url: url,
      });

      var model = new MyModel();

      me.view.model = model;
      me.view.render();

    },

  });
});